<!-- 文件描述: 采集看板--心跳看板 -->
<template>
  <div class="heartbeatBoard">
    <div class="active">
      <div class="active-info">
        <div class="item-info">
          <p class="lable">ip地址：</p>
          <el-input v-model="serverIp" clearable placeholder="请输入ip地址" @change="searchData"><i slot="prefix" class="el-input__icon el-icon-search" @click="searchData"></i></el-input>
        </div>
      </div>
    </div>
    <div class="content">
      <el-table :data="tableData" stripe>
        <el-table-column prop="serverIp" label="IP" min-width="200" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="isPy" label="是否phthon" min-width="120" :show-overflow-tooltip="true" align="center">
          <template slot-scope="scope">
            {{ scope.row.isPy=='1' ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column prop="isProxy" label="是否代理" min-width="120" :show-overflow-tooltip="true" align="center">
          <template slot-scope="scope">
            {{ scope.row.isProxy=='1' ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column prop="threadNum" label="最大线程" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="status" label="状态" width="180" align="center"></el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="changeSize"
          @current-change="changeCurrent"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getHeartBeat } from "@/http/api/acquisitionBoard.js"
export default {
  name: "heartbeatBoard",
  data() {
    return {
      serverIp: "",//ip地址
      tableData: [],//列表数据
      currentPage: 1,//当前页
      pageSize: 10,//每页显示条数
      total: 0,//总条数
    };
  },
  mounted() {
    //获取列表数据
    this.getData();
  },
  methods: {
    //获取列表数据
    async getData() {
      let { body } = await getHeartBeat({ pages: this.currentPage, rows: this.pageSize, serverIp: this.serverIp });
      if(body){
        this.tableData = body.content;
        this.total = body.total;
      }else{
        this.tableData = [];
        this.total = 0;
      }
    },
    //根据任务名称搜索数据
    searchData() {
      this.currentPage = 1;
      this.pageSize = 10;
      this.getData();
    },
    //当前页
    changeCurrent(val) {
      this.currentPage = val;
      this.getData();
    },
    //每页显示条数
    changeSize(val) {
      this.pageSize = val;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.heartbeatBoard {
  padding: 30px 24px;
}
.active {
  display: flex;
  margin-bottom: 30px;
}
.active-info {
  display: flex;
  align-items: center;
  .item-info {
    display: flex;
    align-items: center;
    margin-right: 20px;
    .lable {
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      white-space: nowrap;
    }
  }
}
//分页样式
.pagination{
  margin-top: 20px;
  text-align: right;
}
//表格样式
::v-deep .el-table th.el-table__cell {
  background: #EBF0FF;
}
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background: #F7FAFF;
}
::v-deep .el-table td.el-table__cell {
  border: none;
}
</style>